import React from "react";

const Headermobile = () => {
  return (
    <header className="header-mobile">
      <div className="header-mobile__top">
        <div className="mobile-logo">
          <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
            <img
              src="https://images.linkcdn.cloud/V2/782/logo/logo-926665439.png"
              alt="WebsiteLogo"
              width="125"
              height="27"
            />
          </a>
        </div>
        <div className="mobile-button">
          <div className="mobile-button--menu d-flex justify-content-end align-items-center h-100">
            <img
              src="https://pinoywin.net/themes/6/img/theme-1-icons/small-icons/menu.svg"
              width="50px"
              alt="menu"
              className="d-flex align-items-center"
            />
          </div>
        </div>
      </div>
      <div className="header__mid"></div>
    </header>
  );
};

export default Headermobile;
