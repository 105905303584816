export const sportsbookGames = [
  {
    id: 1,
    img: "/assets/img/sportsbook/afb88.webp",
    alt: "sportsbook game",
    title: "AFB88",
  },
  {
    id: 2,
    img: "/assets/img/sportsbook/cmd368.webp",
    alt: "sportsbook game",
    title: "CMD368",
  },
];
