import React from "react";
import SliderBanner from "../components/section/SliderBanner";
import Jackpot from "../components/section/Jackpot";
import HomeGame1 from "../components/section/HomeGame1";
import HomeGame2 from "../components/section/HomeGame2";
import HomeGame3 from "../components/section/HomeGame3";
import HomeGame4 from "../components/section/HomeGame4";
import HomeSEO from "../components/section/HomeSEO";
import HomeBank from "../components/section/HomeBank";

const Home = () => {
  return (
    <>
      <SliderBanner />
      <Jackpot />
      <HomeGame1 />
      <HomeGame2/>
      <HomeGame3/>
      <HomeGame4/>
      <HomeSEO/>
      <HomeBank/>
    </>
  );
};

export default Home;
