import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="footer-col">
              <div className="footer-service">
                <div className="service-item">
                  <div className="service-icon">
                    <img
                      src="https://pinoywin.net/themes/6/img/theme-1-icons/deposit.svg"
                      width="40px"
                      alt="deposit"
                    />
                  </div>
                  <div className="service-time">
                    <div className="time-title">Deposit</div>
                    <div className="time-number">
                      <span id="depositTime"></span>
                      <span>Mga minuto</span>
                    </div>
                    <div className="progress">
                      <div id="depositTimeBar" className="progress-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="service-item">
                  <div className="service-icon">
                    <img
                      src="https://pinoywin.net/themes/6/img/theme-1-icons/withdraw.svg"
                      width="40px"
                      alt="withdraw"
                    />
                  </div>
                  <div className="service-time">
                    <div className="time-title">Withdraw</div>
                    <div className="time-number">
                      <span id="withdrawTime"></span>
                      <span>Mga minuto</span>
                    </div>
                    <div className="progress">
                      <div
                        id="withdrawTimeBar"
                        className="progress-bar"
                        // role="progressbar"
                        // aria-valuenow=""
                        // aria-valuemin="0"
                        // aria-valuemax="15"
                        // style="width: 0%;"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-col">
              <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
                <div className="footer-download">
                  <img
                    src="https://pinoywin.net/themes/6/img/theme-1-icons/small-icons/android.svg"
                    alt="android"
                  />
                  <span>I-DOWNLOAD NA NGAYON</span>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer-col">
              <div className="footer-nav">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  +18
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  Balita
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  Promosyon
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  Tungkol sa amin
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  Kontakin Kami
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  Termino at Kundisyon
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                >
                  FAQ
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Affiliate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__provider">
        <div className="provider-header">PROVIDER</div>
        <div className="container">
          <div className="provider-list">
            <div className="provider-icon">
              <img
                alt="Microgaming"
                title="Microgaming"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/micro_logo.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="JILI"
                title="JILI"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/jli_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Fa Chai"
                title="Fa Chai"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/fac_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="JDB"
                title="JDB"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/jdb_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Afb Gaming"
                title="Afb Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/afg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Spade Gaming"
                title="Spade Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/spd_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="RED TIGER"
                title="RED TIGER"
                src=" https://images.linkcdn.cloud/global/logo-footer/slot/rtr_footer.webp "
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="AIS GAMING"
                title="AIS GAMING"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/aisg_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="CQ9 Gaming"
                title="CQ9 Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/cq9_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Playstar"
                title="Playstar"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/pls_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="FASTSPIN"
                title="FASTSPIN"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/fastspin_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="HC Game"
                title="HC Game"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/hcg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="NoLimit City"
                title="NoLimit City"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/nlc_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Virtual Tech"
                title="Virtual Tech"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/vrt_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Ameba"
                title="Ameba"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/amb_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Top Trend Gaming"
                title="Top Trend Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/ttg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Play N Go"
                title="Play N Go"
                src="https://images.linkcdn.cloud/global/logo-footer/slot/png_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="LIVE GAME"
                title="LIVE GAME"
                src="https://images.linkcdn.cloud/global/logo-footer/others/lvg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="MIKI Gaming"
                title="MIKI Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/others/miki_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="SABONG DS88"
                title="SABONG DS88"
                src="https://images.linkcdn.cloud/global/logo-footer/others/ds8_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Dream Gaming"
                title="Dream Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/drg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="AFB CASINO"
                title="AFB CASINO"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/afc_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="GD88"
                title="GD88"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/gd8_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="WM Casino"
                title="WM Casino"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/wmc_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Evolution"
                title="Evolution"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/evolution_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="Sexy Gaming"
                title="Sexy Gaming"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/seg_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="LG88"
                title="LG88"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/lg8_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="WE CASINO"
                title="WE CASINO"
                src="https://images.linkcdn.cloud/global/logo-footer/casino/wec_footer.webp"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="AFB88"
                title="AFB88"
                src="https://images.linkcdn.cloud/global/logo-footer/sports/afb_footer.png"
                width="100"
                height="50"
              />
            </div>
            <div className="provider-icon">
              <img
                alt="CMD368"
                title="CMD368"
                src="https://images.linkcdn.cloud/global/logo-footer/sports/cmd_footer.png"
                width="100"
                height="50"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__trademark">
        Copyright © 2021 - 2023 pinoywin is an international registered
        trademark. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
