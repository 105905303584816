export const casinoGames = [
  {
    id: 1,
    img: "/assets/img/casino/drg.webp",
    alt: "popular game",
    title: "Dream Gaming",
  },
  {
    id: 2,
    img: "/assets/img/casino/afb.webp",
    alt: "popular game",
    title: "AFG CASINO",
  },
  {
    id: 3,
    img: "/assets/img/casino/gd8.webp",
    alt: "popular game",
    title: "GD88",
  },
  {
    id: 4,
    img: "/assets/img/casino/wmc.webp",
    alt: "popular game",
    title: "GD88",
  },
  {
    id: 5,
    img: "/assets/img/casino/evo.webp",
    alt: "popular game",
    title: "Evolution",
  },
  {
    id: 6,
    img: "/assets/img/casino/seg.webp",
    alt: "popular game",
    title: "Evolution",
  },
  {
    id: 7,
    img: "/assets/img/casino/lg8.webp",
    alt: "popular game",
    title: "LG88",
  },
  {
    id: 8,
    img: "/assets/img/casino/wec.webp",
    alt: "popular game",
    title: "Coming Soon",
  },
];
