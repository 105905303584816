import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";

const Header = () => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const date = now.toDateString();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      const amPm = hours >= "12" ? "PM" : "AM";
      const formattedTime = `${date} ${hours}:${minutes}:${seconds} ${amPm}`;
      setCurrentTime(formattedTime);
    };

    updateTime();

    const intervalId = setInterval(updateTime, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="header__top">
          <div className="container-lg">
            <Row>
              <Col lg={3} className="d-flex align-items-center">
                <div className=" d-flex align-items-center">
                  <div className="header-top-date  ">
                    <div className="header-time ">{currentTime}</div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="header-marquee">
                  <div className="marquee-icon ">
                    <img
                      src="/assets/img/broadcast.svg"
                      alt="notification"
                      className="me-2"
                    />
                  </div>
                  <p className="marquee">
                    FOR BETTER AND FASTER TRANSACTION PLEASE TRY OUR NEW DEPOSIT
                    METHOD CHOOSE GCASH (DIRECT) MINIMUM DEPOSIT 50 PHP THANK
                    YOU
                  </p>
                </div>
              </Col>
              <Col lg={3} className="d-flex justify-content-end">
                <div className="header-menu">
                  <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
                    <div className="menu-icon">
                      <img src="/assets/img/help.svg" alt="help" />
                    </div>
                  </a>
                  <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
                    <div className="menu-icon">
                      <img src="/assets/img/android.svg" alt="android" />
                    </div>
                  </a>
                  <div
                    className="menu-item"
                    data-toggle="modal"
                    data-target="#currencyModal"
                  >
                    <div className="item-icon">
                      <img src="/assets/img/php.svg" alt="peso" />
                    </div>
                    <div className="item-name">Peso</div>
                  </div>
                  <div
                    className="menu-item"
                    data-toggle="modal"
                    data-target="#languageModal"
                  >
                    <div className="item-icon lang-icon">
                      <img src="/assets/img/philippines.webp" alt="tl" />
                    </div>
                    <div className="item-name">Tagalog</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="header__mid">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 align-self-center">
                <div className="mid-profile">
                  <div className="profile-greetings">
                    <span>Please Login First!</span>
                  </div>
                </div>
                <div className="mid-link">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    className="link-item"
                  >
                    <div className="link-icon">
                      <img src="/assets/img/news.svg" width="20" alt="news" />
                    </div>
                    <div className="link-title">Balita</div>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    className="link-item"
                  >
                    <div className="link-icon">
                      <img
                        src="/assets/img/promotion.svg"
                        width="20"
                        alt="promotion"
                      />
                    </div>
                    <div className="link-title">Promosyon</div>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    className="link-item"
                  >
                    <div className="link-icon">
                      <img
                        src="/assets/img/event.svg"
                        alt="events"
                        width="20"
                      />
                    </div>
                    <div className="link-title">Kaganapan</div>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    className="link-item"
                    target="_blank"
                  >
                    <div className="link-icon">
                      <img
                        src="/assets/img/affiliate.svg"
                        alt="affliates"
                        width="20"
                      />
                    </div>
                    <div className="link-title">Agents</div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 align-self-center">
                <div className="mid-logo">
                  <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
                    <img
                      alt="WebsiteLogo"
                      src="https://images.linkcdn.cloud/V2/782/logo/logo-926665439.png"
                      width="250"
                      height="54"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-5 align-self-center">
                <div className="mid-form">
                  <div className="form-button">
                    <button
                      className="btn-login"
                      type="button"
                      data-toggle="modal"
                      data-target="#loginModal"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/theme-1-icons/login.svg"
                        alt=""
                      />{" "}
                      Mag log in
                    </button>
                    <button className="btn-register">
                      <img
                        src="https://pinoywin.net/themes/6/img/theme-1-icons/register.svg"
                        alt=""
                      />{" "}
                      Magrehistro
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-nav  d-lg-block d-md-none d-sm-none">
          <nav className="navbar">
            <div className="container-fluid justify-content-center ">
              <div className="navbar-holder  ">
                <div className="navbar-nav d-flex">
                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-popular"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/popular.svg"
                        width="80"
                        alt="popular"
                      />
                      <div className="nav-link-name text-white">Sikat</div>
                    </a>
                  </div>

                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-slot"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/slot.svg"
                        width="80"
                        alt="slot"
                      />
                      <div className="nav-link-name">Slot</div>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-livegames"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/livegames.svg"
                        width="80"
                        alt="livegame"
                      />
                      <div className="nav-link-name">Live Game</div>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-casino"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/casino.svg"
                        width="80"
                        alt="casino"
                      />
                      <div className="nav-link-name">Casino</div>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-sport"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/sport.svg"
                        width="80"
                        alt="sport"
                      />
                      <div className="nav-link-name">Sportsbook</div>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className="nav-link nav-link-arcade"
                      href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                    >
                      <img
                        src="https://pinoywin.net/themes/6/img/navigation/arcade.svg"
                        width="80"
                        alt="arcade"
                      />
                      <div className="nav-link-name">Arcade</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
