import React from "react";
import Casino from "../carousel/Casino";

const HomeGame3 = () => {
  return (
    <section className="mb-4">
      <div className="container-fluid">
        <div className="game-container">
          <div className="game-container__header">
            <div className="game-title">
              <div className="icon">
                <img
                  src="https://pinoywin.net/themes/6/img/navigation/casino.svg"
                  width="80"
                  alt="popular icon"
                />
              </div>
              <h6>Casino</h6>
            </div>
            <div className="game-action">
              <div className="action-button">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
                  className="btn-custom-sm text-decoration-none"
                >
                  Iba pang laro
                </a>
              </div>
              <div className="action-navigation">
                <button
                  className="btn-custom-sm navigation-prev--popular"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-4fc9264946cd4b8e"
                  aria-disabled="false"
                >
                  <i className="fas fa-angle-left"></i>
                </button>
                <button
                  className="btn-custom-sm navigation-next--popular swiper-button-disabled"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-4fc9264946cd4b8e"
                  aria-disabled="true"
                >
                  <i className="fas fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          {/* {popularGames} */}
          <div className="game--container__list">
            <Casino />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeGame3;
