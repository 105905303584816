import React from "react";

const Footermobile = () => {
  return (
    <footer className="footermobile">
      <div className="footer__trademark">
        Copyright © 2021 - 2023 pinoywin is an international registered
        trademark. All rights reserved.
      </div>
    </footer>
  );
};

export default Footermobile;
