export const popularGames = [
  {
    id: 1,
    img: "https://images.linkcdn.cloud/global/game-list/slot/playstar/PSS-ON-00148.png",
    alt: "popular game",
    title: "Sugar Boom",
  },
  {
    id: 2,
    img: "/assets/img/popular/224.webp",
    alt: "popular game",
    title: "Go Rush",
  },
  {
    id: 3,
    img: "/assets/img/popular/133.webp",
    alt: "popular game",
    title: "Good Furtune M",
  },
  {
    id: 4,
    img: "/assets/img/popular/S-RH02.jpg",
    alt: "popular game",
    title: "Royale House",
  },
  {
    id: 5,
    img: "/assets/img/popular/S-LK03.jpg",
    alt: "popular game",
    title: "Legacy Of Kong Maxways",
  },
  {
    id: 6,
    img: "/assets/img/popular/199.webp",
    alt: "popular game",
    title: "Tongits Go",
  },
  {
    id: 7,
    img: "/assets/img/popular/23.webp",
    alt: "popular game",
    title: "Candy Baby",
  },
  {
    id: 8,
    img: "/assets/img/popular/151.webp",
    alt: "popular game",
    title: "Super Bingo",
  },
  {
    id: 9,
    img: "/assets/img/popular/62.webp",
    alt: "popular game",
    title: "Dice",
  },
  {
    id: 10,
    img: "/assets/img/popular/66.webp",
    alt: "popular game",
    title: "Lucky Number",
  },
  {
    id: 11,
    img: "/assets/img/popular/118.webp",
    alt: "popular game",
    title: "Big Small",
  },
  {
    id: 12,
    img: "/assets/img/popular/179.webp",
    alt: "popular game",
    title: "Win Drop",
  },
  {
    id: 13,
    img: "/assets/img/popular/47.webp",
    alt: "popular game",
    title: "Charge Buffalo",
  },
  {
    id: 13,
    img: "/assets/img/popular/49.webp",
    alt: "popular game",
    title: "Super Ace",
  },
  {
    id: 14,
    img: "/assets/img/popular/197.webp",
    alt: "popular game",
    title: "Color Game",
  },
  {
    id: 15,
    img: "/assets/img/popular/103.png",
    alt: "popular game",
    title: "Golden Empire",
  },
  {
    id: 16,
    img: "/assets/img/popular/229.webp",
    alt: "popular game",
    title: "Mines",
  },
  {
    id: 17,
    img: "/assets/img/popular/godOfWealth.png",
    alt: "popular game",
    title: "God Of Wealth",
  },
];
