import React from "react";
import { useState, useEffect } from "react";

const Jackpot = () => {
  const [number, setNumber] = useState(2000300.0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((prevNumber) => prevNumber + 0.01);
    }, 30);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <section className="home__jackpot my-lg-5 ">
        <div className="container">
          <div className="jackpot-gif">
            <div className="jackpot-amount">
              PHP 
              <span className="ml-3 ms-3" id="amount-jackpot">
              
                {number.toLocaleString("en-US", { minimumFractionDigits: 2 })}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Jackpot;
