import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Chatbot from "../header/Chatbox";
import Headermobile from "../header/Headermobile";
import Footermobile from "../footer/Footermobile";
const Layout = () => {
  return (
    <>
      <Header />
      <Headermobile/>
        <Outlet />
        <Chatbot/>
      <Footer />
      <Footermobile/>
    </>
  );
};

export default Layout;
