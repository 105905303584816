import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper/modules";
import { bankPayment } from "../../data/bankpayment.data";
const Bankswiper = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        grabCursor={true}
        // loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        modules={[Pagination]}
        className="mySwiper px-4 p-3"
      >
        {bankPayment.map((game) => (
          <SwiperSlide key={game.id} className=" ">
            <div className="slide-border d-flex align-items-center">
              <div className="bank-holder" style={{ width: "173px" }}>
                <a href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial">
                  <div className="bank-img">
                    <img title="Microgaming" alt={game.alt} src={game.img} />

                    <h6 className="bank-status online">{game.title}</h6>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Bankswiper;
