import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
const SliderBanner = () => {
  return (
    <>
      <section>
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src="/assets/img/slider/hc-millions-bonus-10-30-nov-653fa59aac821.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/paypro-desktop-650c031c13aa8.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/fishing-64e333452d33f.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/daily-64e33abb2a7e2.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/welcome-bonus-64e33ad128cf8.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/domain-desktop-650c0dad0c641.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/weekly-64e332faf3382.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/loyalty-points-64e3330f6a244.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/jackpot-64e3332a665c6.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/sports-book-64e3333a545b0.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/assets/img/slider/pragmatic-30-oct-27-nov-653770d2a9d45.webp"
              alt="lucky! bunny girl"
              className="w-100"
            />
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default SliderBanner;
