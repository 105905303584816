import React from "react";
import Seocontent from "./Seocontent";

const HomeSEO = () => {
  return (
    <section>
      <div className="container-fluid">
        <div className="game__seo">
          <div className="text-white ">
            Pinoywin: Best Online Casino Site in the Philippines
          </div>
          <Seocontent />
        </div>
      </div>
    </section>
  );
};

export default HomeSEO;
