import React from "react";
import Bankswiper from "../carousel/Bankswiper";

const HomeBank = () => {
  return (
    <section className="home__bank">
      <div className="container">
        <div className="bank-header">Paraan ng Pagbayad</div>
        <Bankswiper />
      </div>
    </section>
  );
};

export default HomeBank;
