import React from "react";

const Chatbot = () => {
  return (
    <div className="chatbot-wrapp">
      <div className="chat-img">
        <a
          href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
          target="_blank"
          rel="noreferrer"
        >
          <span className="d-none">d</span>
        </a>
      </div>
    </div>
  );
};

export default Chatbot;
