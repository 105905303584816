export const slotGames = [
  {
    id: 1,
    img: "/assets/img/slot/mic.webp",
    alt: "popular game",
    title: "Microgaming",
  },
  {
    id: 2,
    img: "/assets/img/slot/jli.webp",
    alt: "popular game",
    title: "JILI",
  },
  {
    id: 3,
    img: "/assets/img/slot/fac.webp",
    alt: "popular game",
    title: "Fa Chai",
  },
  {
    id: 4,
    img: "/assets/img/slot/jdb.webp",
    alt: "popular game",
    title: "JDB",
  },
  {
    id: 5,
    img: "/assets/img/slot/afg.webp",
    alt: "popular game",
    title: "Afb Gaming",
  },
  {
    id: 6,
    img: "/assets/img/slot/spd.webp",
    alt: "popular game",
    title: "Spade Gaming",
  },
  {
    id: 7,
    img: "/assets/img/slot/rtr.webp",
    alt: "popular game",
    title: "RED TIGER",
  },
  {
    id: 8,
    img: "/assets/img/slot/aisg.webp",
    alt: "popular game",
    title: "AIS GAMING",
  },
  {
    id: 9,
    img: "/assets/img/slot/cq9.webp",
    alt: "popular game",
    title: "CQ9 GAMING",
  },
  {
    id: 10,
    img: "/assets/img/slot/pls.webp",
    alt: "popular game",
    title: "Playstar",
  },
  {
    id: 11,
    img: "/assets/img/slot/hcg.webp",
    alt: "popular game",
    title: "HC Game",
  },
  {
    id: 12,
    img: "/assets/img/slot/nlc.webp",
    alt: "popular game",
    title: "NoLimit City",
  },
  {
    id: 13,
    img: "/assets/img/slot/vrt.webp",
    alt: "popular game",
    title: "Virtual Tech",
  },
  {
    id: 14,
    img: "/assets/img/slot/amb.webp",
    alt: "popular game",
    title: "Ameba",
  },
  {
    id: 15,
    img: "/assets/img/slot/ttg.webp",
    alt: "popular game",
    title: "Top Trend Gaming",
  },
  {
    id: 16,
    img: "/assets/img/slot/png.webp",
    alt: "popular game",
    title: "Play N Go",
  },
];
