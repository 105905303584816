export const bankPayment = [
  {
    id: 1,
    img: "/assets/img/bank/gcash.webp",
    alt: "popular game",
    title: "Online",
  },
  {
    id: 2,
    img: "/assets/img/bank/payprophpn.webp",

    alt: "popular game",
    title: "Online",
  },
  {
    id: 3,
    img: "/assets/img/bank/payprophpd.webp",

    alt: "popular game",
    title: "Online",
  },
  {
    id: 4,
    img: "/assets/img/bank/maya.webp",

    alt: "popular game",
    title: "Online",
  },
  {
    id: 5,
    img: "/assets/img/bank/union.webp",

    alt: "popular game",
    title: "Online",
  },
];
