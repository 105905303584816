import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { popularGames } from "../../data/popular.data";
const Gameswiper = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        grabCursor={true}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 9,
            spaceBetween: 30,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper px-4 p-3"
      >
        {popularGames.map((game) => (
          <SwiperSlide key={game.id} className=" ">
            <div className="games-holder w-100 py-3  ">
              <a
                className="main sekarang main-sekarang-alert text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/pinoywinofficial"
              >
                <div className="games-img">
                  <img src={game.img} width="110" height="80" alt={game.alt} />
                </div>
                <div className="hover-play">
                  <i className="fas fa-play"></i>
                  dasdasd
                </div>
                <div className="games-bottom text-center">
                  <div className="info-name">{game.title}</div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Gameswiper;
